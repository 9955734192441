import { apiGet } from "src/services/apiService";
import { DepositsProps } from "src/types/deposit";
import useSWR from "swr";

export default function useDeposits({
  query,
  page = 0,
}: {
  query: string;
  page: number;
}) {
  const {
    data: response,
    isValidating,
    mutate,
  } = useSWR<DepositsProps>(
    query &&
      `/bank/deposits?${query}&page=${page}&sortAttributeName=createdAt&sortMode=DESC`,
    apiGet,
    {
      dedupingInterval: 10000,
    }
  );

  return {
    data: response?.data || [],
    numberOfElements: response?.numberOfElements || 0,
    totalElements: response?.totalElements || 0,
    totalPages: response?.totalPages || 0,
    page: response?.page || 0,
    pageSize: response?.pageSize || 0,
    loading: !response?.data,
    isValidating,
    mutate,
  };
}
