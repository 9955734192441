import {
  Box,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiTimeZoneLine } from "react-icons/ri";
import { changeTimeZone } from "src/lib/api/users";
import useParameters from "src/swr/use-parameters";
import SelectLanguage from "../dashboard/SelectLanguage";

export default function MenuTimezone() {
  const { t } = useTranslation(["dashboard", "home"]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { parameters } = useParameters();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [timeZoneState, setTimeZoneState] = useState<string>("");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (parameters) {
      setTimeZoneState(parameters.USER_TIMEZONE.value);
    }
  }, [parameters]);

  const handleTimeZoneChange = (event: SelectChangeEvent) => {
    setTimeZoneState(event.target.value);

    try {
      changeTimeZone(Number(event.target.value));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ ml: "auto", position: "relative", pr: isMobile ? 0 : 2 }}>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ padding: 0 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <RiTimeZoneLine
          size={isMobile ? 24 : 28}
          style={{ cursor: "pointer" }}
        />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          sx: {
            width: "320px",
            padding: "2rem 1.5rem",
            position: "absolute",
            background: "#01080c",
            left: "50%",
            top: "50px",
            transform: "translate(-50%)",
            border: " 2px solid #04141d",
            mt: 3.5,
            "& fieldset": {
              border: "none",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {" "}
        <Box>
          <Typography variant="subtitle1" pb={2}>
            {t("language-and-timezone")}
          </Typography>
          <FormControl fullWidth>
            <Typography variant="body1" color={"#AAA"} pb={1}>
              {t("language")}
            </Typography>
            <SelectLanguage />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <Typography variant="body1" color={"#AAA"} pb={1}>
              {t("timezone")}
            </Typography>
            <Select value={timeZoneState} onChange={handleTimeZoneChange}>
              <MenuItem value={-12}>UTC-12:00</MenuItem>
              <MenuItem value={-11}>UTC-11:00</MenuItem>
              <MenuItem value={-10}>UTC-10:00</MenuItem>
              <MenuItem value={-9}>UTC-09:00</MenuItem>
              <MenuItem value={-8}>UTC-08:00</MenuItem>
              <MenuItem value={-7}>UTC-07:00</MenuItem>
              <MenuItem value={-6}>UTC-06:00</MenuItem>
              <MenuItem value={-5}>UTC-05:00</MenuItem>
              <MenuItem value={-4}>UTC-04:00</MenuItem>
              <MenuItem value={-3}>UTC-03:00</MenuItem>
              <MenuItem value={-2}>UTC-02:00</MenuItem>
              <MenuItem value={-1}>UTC-01:00</MenuItem>
              <MenuItem value={0}>UTC+00:00</MenuItem>
              <MenuItem value={1}>UTC+01:00</MenuItem>
              <MenuItem value={2}>UTC+02:00</MenuItem>
              <MenuItem value={3}>UTC+03:00</MenuItem>
              <MenuItem value={4}>UTC+04:00</MenuItem>
              <MenuItem value={5}>UTC+05:00</MenuItem>
              <MenuItem value={6}>UTC+06:00</MenuItem>
              <MenuItem value={7}>UTC+07:00</MenuItem>
              <MenuItem value={8}>UTC+08:00</MenuItem>
              <MenuItem value={9}>UTC+09:00</MenuItem>
              <MenuItem value={10}>UTC+10:00</MenuItem>
              <MenuItem value={11}>UTC+11:00</MenuItem>
              <MenuItem value={12}>UTC+12:00</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Menu>
    </Box>
  );
}
