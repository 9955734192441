import { Box, Typography } from "@mui/material";
import type { FC } from "react";
import Logo from "./Logo";

const MaintenanceScreen: FC = () => (
  <Box
    sx={{
      alignItems: "center",
      backgroundColor: "background.paper",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      left: 0,
      p: 3,
      position: "fixed",
      top: 0,
      width: "100%",
      zIndex: 2000,
    }}
  >
    <Logo />
    <Box sx={{ mt: 2, mx: 5, textAlign: "center", maxWidth: 800 }}>
      <Typography fontSize={18} sx={{ color: "#fff" }}>
        Caro Trader, <br></br> Estamos realizando uma manutenção essencial, para melhorar o desempenho da plataforma. Durante esse
        horário, a plataforma ficará inacessível. Por favor, evite iniciar novas
        negociações durante o prazo de manutenção.
      </Typography>
      <Typography fontSize={18} sx={{ color: "#fff", mt: 2 }}>
        Pedimos desculpas por qualquer inconveniente e agradecemos a
        compreensão.
      </Typography>
    </Box>
  </Box>
);

export default MaintenanceScreen;
