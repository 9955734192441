import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import LayoutContext from "src/contexts/LayoutContext";
import TradingViewContext from "src/contexts/TradingViewContext";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: 550,
      border: "1px rgba(255, 255, 255, 0.2) solid",
    },
    chip: {
      width: 18,
      height: 18,
      backgroundColor: "rgba(145, 158, 171, 0.24)",
      borderRadius: 0.51,
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  })
);

function OutsideMarketModal() {
  const { t } = useTranslation("dashboard");
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSymbol, updateSymbol } = useContext(TradingViewContext);
  const { layout, setModalOutsideMarket } = useContext(LayoutContext);
  const handleCloseModalReal = () => {
    setModalOutsideMarket(false, "");
  };

  const handleCloseOutsideMarketModal = async () => {
    if (selectedSymbol !== "IDXUSDT") {
      if (layout.relaodPage) {
        const storedSymbols = localStorage.getItem("selectedMenuSymbols");
        console.log("storedSymbols", storedSymbols, selectedSymbol);
        if (storedSymbols) {
          const parsedSymbols = JSON.parse(storedSymbols);
          const updatedSymbols = parsedSymbols.filter(
            (item) => item.symbol !== selectedSymbol
          );
          console.log("updatedSymbols", updatedSymbols);
          localStorage.setItem(
            "selectedMenuSymbols",
            JSON.stringify(updatedSymbols)
          );
        } else {
          localStorage.removeItem("selectedMenuSymbols");
        }
        updateSymbol("IDXUSDT");
        window.location.href = "/traderoom";
      }
      handleCloseModalReal();
    } else {
    }
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={layout.modalOutsideMarket}
      onClose={() => handleCloseModalReal()}
    >
      <Paper
        sx={{ p: 3, minWidth: isMobile ? "80%" : null }}
        className={classes.paper}
      >
        <IconButton
          aria-label="close"
          onClick={() => handleCloseModalReal()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <Stack direction="row" spacing={2}>
          <Stack direction={"column"} spacing={2} py={5}>
            <Typography
              variant="subtitle1"
              fontWeight={300}
              textAlign={"center"}
            >
              {layout.outsideMarketText}
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={(e: any) => handleCloseOutsideMarketModal()}
          >
            Ok
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}

export default OutsideMarketModal;
