import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  Accordion,
  AccordionSummary,
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { format } from "date-fns";
import { orderBy } from "lodash";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLastBar } from "src/components/TVChartContainer/streaming";
import { SYMBOL_IMAGES } from "src/constants";
import TradingViewContext from "src/contexts/TradingViewContext";
import useApiData from "src/hooks/useApiData";
import useAvailableSymbols from "src/swr/use-available-symbols";
import useParameters from "src/swr/use-parameters";
import { getResulteOnLive } from "src/utils/getResultOnLine";
import { scrollStyle, winLostIconStyle } from ".";

export default function LiveOrders() {
  const { t } = useTranslation("dashboard");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    "panel1"
  );

  const { parameters, loading } = useParameters();
  const { selectedSymbol } = useContext(TradingViewContext);
  const { userLiveOperations } = useApiData();
  const lastDailyBar = getLastBar(selectedSymbol, "OperationsHistoricTab");
  const { selectedSymbolData } = useAvailableSymbols();

  const fee = useMemo(() => {
    return 100 - (selectedSymbolData?.payout || 90);
  }, [selectedSymbolData]);

  const handleChangeAccordion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccordion(newExpanded ? panel : false);
    };

  return (
    <Box sx={scrollStyle}>
      {Object.keys(userLiveOperations).length === 0 ? (
        <Typography
          color="#919eab"
          variant="body1"
          py={2}
          px={1.4}
          textAlign={isMobile ? "center" : "left"}
        >
          {t("no_operations")}
        </Typography>
      ) : (
        orderBy(
          Object.values(userLiveOperations),
          [(item) => new Date(item.createdAt)],
          ["desc"]
        ).map((item) => (
          <Accordion
            key={item.id}
            expanded={expandedAccordion === item.id}
            onChange={handleChangeAccordion(item.id)}
            elevation={0}
            sx={{
              "& .MuiAccordionSummary-content": {
                display: "flex",
                justifyContent: "space-around",
              },
              "& .MuiAccordionSummary-root": {
                bgcolor: "transparent",
                background: "transparent",
                height: "64px",
                border: `0`,
                borderBottom: `1px solid #0d1b24`,
                "&:last-of-type": {
                  border: 0,
                },
              },
              background: "transparent",
              "&::before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{
                pointerEvents: "none",
                "& .MuiAccordionSummary-expandIconWrapper": {
                  display: "none",
                },
                "& .info": {
                  display: "flex",
                  alignItems: "center",
                },
                "& .profit": {
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "0.25rem",
                },
              }}
            >
              <div className="info">
                {/* @ts-ignore */}
                <img width={50} src={SYMBOL_IMAGES[item.symbol].image} alt="" />

                <div>
                  <Typography color={"#EEE"}>{item.symbol}</Typography>
                  <Typography variant="body1" color={"#AAA"}>
                    {format(new Date(item.candleStartTime), "HH:mm")} /{" "}
                    {item.candleTimeFrame}
                  </Typography>
                </div>
              </div>
              <div className="profit">
                {item.direction.toUpperCase() === "BULL" ? (
                  <Box sx={winLostIconStyle} ml={2} color={"#1bba87"}>
                    <TrendingUpIcon fontSize={isMobile ? "large" : "small"} />
                  </Box>
                ) : (
                  <Box sx={winLostIconStyle} ml={2} color={"#FF025C"}>
                    <TrendingDownIcon fontSize={isMobile ? "large" : "small"} />
                  </Box>
                )}

                <>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <OrderResult
                      order={item}
                      fee={fee}
                      lastDailyBar={lastDailyBar}
                    />
                  )}
                </>
              </div>
            </AccordionSummary>
          </Accordion>
        ))
      )}
    </Box>
  );
}

interface OrderResultProps {
  order: any;
  fee: number;
  lastDailyBar: any;
}

const OrderResult: FC<OrderResultProps> = ({ order, fee, lastDailyBar }) => {
  const [result, setResult] = useState({ color: "", simbol: "", result: "" });

  useEffect(() => {
    const updatedResult = getResulteOnLive({
      order,
      fee,
      lastDailyBar,
    });
    setResult(updatedResult);
  }, [order, fee, lastDailyBar]);

  return (
    <Typography color={result.color} variant="subtitle1">
      {`${result.simbol}${result.result}`}
    </Typography>
  );
};
