import { Close } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: 450,
      width: "80%",
      border: "1px solid #031118",
      background: "#000406e8",
      padding: "2rem",
      outline: "none",
      borderRadius: "12px",
      backdropFilter: "blur(12px)",
    },
  })
);

const modalStyle = {
  "& .modal_content": {
    justifyContent: "space-evenly",
    alignItems: "center",
    gap: "1.25rem",
  },
};

function IdleModal({ openModal }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation("dashboard");

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Modal
      aria-labelledby="idle-modal-title"
      aria-describedby="idle-modal-description"
      open={openModal}
      onClose={handleRefresh} // Fecha e recarrega
      sx={modalStyle}
    >
      <Paper
        sx={{ minWidth: isMobile ? "80%" : null }}
        className={classes.paper}
      >
        <IconButton
          aria-label="close"
          onClick={handleRefresh}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#606f79",
            "&:hover": {
              color: "#FFF",
              background: "transparent",
            },
          }}
        >
          <Close />
        </IconButton>
        <Stack direction="column" spacing={2}>
          <Typography variant="h6" fontWeight="400">
            {t("idle_message_title")}
          </Typography>

          <Typography variant="body1" sx={{ color: "#9db6c5" }}>
            {t("idle_message_description")}
          </Typography>

          <Button
            size="large"
            sx={{ backgroundColor: "#070e12", borderRadius: "8px" }}
            variant="contained"
            onClick={handleRefresh} // Recarrega quando o botão é clicado
            fullWidth
          >
            {t("try_again_button")}
          </Button>
        </Stack>
      </Paper>
    </Modal>
  );
}

export default IdleModal;
