import { Skeleton, Typography, useMediaQuery } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";

import CountdonwContext from "src/contexts/CountdownContext";

function Countdown() {
  const { t } = useTranslation("dashboard");
  const { count, timeFormat } = useContext(CountdonwContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (timeFormat === "") {
    return <Skeleton variant="text" sx={{ ml: 0.4 }} width={80} height={15} />;
  }

  return (
    <Typography
      fontWeight={600}
      color={count < 6 ? "#FF5382" : "#00DB97"}
      sx={{ ml: 0.4 }}
      pt={isMobile ? "0.57rem" : 0}
      variant="body2"
    >
      {t("time")}: {timeFormat}
    </Typography>
  );
}

export default Countdown;
