import { getBrokerNowTime } from "src/components/TVChartContainer/datafeed";

export const isValidOrder = (order) => {
  const brokerTime = getBrokerNowTime();
  const orderTime = new Date(order.candleStartTime).getTime();
  const timeFrameInMs = {
    M1: 1 * 60 * 1000,
    M5: 5 * 60 * 1000,
    M15: 15 * 60 * 1000,
  }[order.candleTimeFrame];

  return brokerTime - orderTime < timeFrameInMs;
};
