import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import PusingDot from "../lottie/PusingDot";

type DefaultCoinChartProps = {
  name: string;
  image: string;
  ping?: boolean;
  center?: boolean;
  marketStatus?: "OPEN" | "CLOSED";
};

export default function DefaultCoinChart({
  name,
  image,
  ping,
  center,
  marketStatus,
}: DefaultCoinChartProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isMarketOpen = marketStatus === "OPEN"; // Verificar se o mercado está aberto

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: "center",
        alignSelf: "center",
        justifyContent: center ? "center" : "flex-start",
        height: "24px",
      }}
    >
      <img
        src={image}
        style={{ width: isMobile ? 24 : 32, height: isMobile ? 24 : 32 }}
        alt={name}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: "0.2rem !important",
        }}
      >
        <Typography color="white" fontSize={12}>
          {name}
        </Typography>

        {!isMobile && ping && (
          <Box sx={{ ml: 0.5, mt: 0.8 }}>
            <PusingDot isMarketOpen={isMarketOpen} />
          </Box>
        )}
      </Box>
    </Stack>
  );
}
