import { useContext, useEffect, useMemo } from "react";
import LayoutContext from "src/contexts/LayoutContext";
import TradingViewContext from "src/contexts/TradingViewContext";
import { apiGet } from "src/services/apiService";
import useSWR from "swr";
import useFavoritesSymbols from "./use-favorites-symbols";

export default function useAvailableSymbols() {
  const { data, isValidating, mutate } = useSWR<any>(
    "/orders/availableSymbols",
    apiGet,
    {
      dedupingInterval: 10000,
    }
  );

  const { favoritesSymbols } = useFavoritesSymbols();

  const { setModalOutsideMarket } = useContext(LayoutContext);
  const { selectedSymbol } = useContext(TradingViewContext);

  const symbols = useMemo(() => {
    if (data) {
      return data.map((item) => ({
        ...item,
        favorite:
          Array.isArray(favoritesSymbols) &&
          favoritesSymbols.some(
            (fav) => fav.symbol === item.symbol && fav.favorite
          ),
      }));
    }
    return [];
  }, [data, favoritesSymbols]);

  const selectedSymbolData = useMemo(() => {
    return (
      symbols.find((item) => item.symbol === selectedSymbol) || {
        symbol: "BTCUSDT",
        symbolType: "CRYPTO",
        symbolLabel: "BTC/USDT",
        marketStatus: "OPEN",
        openMarketTime: null,
        closeMarketTime: null,
        payout: 90.0,
        hrs24PercentualChange: 0,
      }
    );
  }, [symbols, selectedSymbol]);

  useEffect(() => {
    if (selectedSymbolData && selectedSymbolData.marketStatus !== "OPEN") {
      setModalOutsideMarket(
        true,
        `O gráfico do índice ${selectedSymbolData.symbol} está fechado no momento.`
      );
    }
  }, [selectedSymbolData, setModalOutsideMarket]);

  return {
    symbols,
    selectedSymbolData,
    loading: !data,
    isValidating,
    mutate,
  };
}
