import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { createContext, useEffect, useReducer } from "react";
import {
  EntityId,
  IChartingLibraryWidget,
} from "src/charting_library/charting_library";
import { getLastBar } from "src/components/TVChartContainer/streaming";
import { Order, OrderStatusEnum } from "src/types/order";
import { correctSymbol, updateCurrentChartAnalysis } from "src/utils";
import { convertTimeToCandlePoint } from "src/utils/candle";
import { delay } from "src/utils/delay";
import formatCurrency from "src/utils/formatCurrency";
import { useLocalStorage } from "usehooks-ts";

type ChartOrders = { id: string; entities: EntityId[] };

type GroupedOrders = {
  id: string;
  orders: Order[];
};

type WinLoseOrderType = {
  candleStartTime: number;
  orderDirection: string;
  price: number;
  ordersIds: string[];
  symbol: string;
  value: number;
};

interface OrderItem {
  orderId: string;
  order: any;
  orderTradingView: any;
  direction: "buy" | "sell";
  timeframe: string;
  candleStartTime: number;
  totalOrderValue: number;
  symbol: string;
  status: string;
}

interface State {
  tvWidget: IChartingLibraryWidget | null;
  widgetReady: boolean;
  chartOrders: ChartOrders[];
  eventsPending: any[];
  candleTime: number;
  selectedSymbol: string;
  ordersPending: OrderItem[];
  ordersOpened: OrderItem[];
}

interface BollingerBands {
  sma: number;
  upperBand: number;
  lowerBand: number;
  maxHeight: number;
}

export interface TradingViewContextValue extends State {
  setWidget: (tvWidget: IChartingLibraryWidget) => void;
  addChartOrders: (orders: Order[], currentTimeframe: string) => void;
  setChartOrders: (chartOrders: ChartOrders[]) => void;
  removeChartOrders: (orders: GroupedOrders[]) => void;
  winLoseChartOrder: (orders: WinLoseOrderType, status: string) => void;
  removeOrdersByTimeframe: (timeframe: string) => void;
  setCandleTime: (timestamp: number) => void;
  addEventPending: (event: any) => void;
  removeEventPending: () => void;
  updateSymbol: (symbol: string) => void;
  closeAllOrders: () => void;
  removeOrderById: (orderId: string) => void;
  removeOrdersBySymbol: (symbol: string) => void;
  removeOrderOpenedById: (orderId: string) => void;
  removeOrderPendingById: (orderId: string) => void;
}

interface TradingViewProviderProps {
  children?: ReactNode;
}

const initialState: State = {
  tvWidget: null,
  widgetReady: false,
  chartOrders: [],
  eventsPending: [],
  candleTime: 0,
  selectedSymbol: "IDXUSDT",
  ordersPending: [],
  ordersOpened: [],
};

type Action =
  | { type: "SET_TV_WIDGET"; payload: IChartingLibraryWidget }
  | { type: "SET_CHART_ORDERS"; payload: ChartOrders[] }
  | { type: "SET_EVENTS_PENDING"; payload: any }
  | { type: "REMOVE_EVENTS_PENDING"; payload: any }
  | { type: "SET_CANDLE_TIME"; payload: number }
  | { type: "SET_SELECTED_SYMBOL"; payload: string }
  | { type: "ADD_ORDER_PENDING"; payload: OrderItem }
  | { type: "ADD_ORDER_OPENED"; payload: OrderItem }
  | { type: "UPDATE_ORDER_OPENED"; payload: OrderItem[] }
  | { type: "REMOVE_ORDER_BY_ID"; payload: string }
  | { type: "REMOVE_ORDER_PENDING_BY_ID"; payload: string }
  | { type: "REMOVE_ORDER_OPENED_BY_ID"; payload: string }
  | { type: "SET_COUNTDOWN_VALUE"; payload: number };

const handlers: Record<string, (state: State, action: Action) => State> = {
  SET_TV_WIDGET: (state, action) => ({
    ...state,
    tvWidget: action.payload,
  }),
  SET_CHART_ORDERS: (state, action) => ({
    ...state,
    chartOrders: action.payload,
  }),
  SET_EVENTS_PENDING: (state, action) => ({
    ...state,
    eventsPending: [...state.eventsPending, action.payload],
  }),
  REMOVE_EVENTS_PENDING: (state) => ({
    ...state,
    eventsPending: [],
  }),
  SET_SELECTED_SYMBOL: (state, action) => ({
    ...state,
    selectedSymbol: action.payload,
  }),
  ADD_ORDER_PENDING: (state, action) => ({
    ...state,
    ordersPending: [...state.ordersPending, action.payload],
  }),
  ADD_ORDER_OPENED: (state, action) => ({
    ...state,
    ordersOpened: [...state.ordersOpened, action.payload],
  }),
  UPDATE_ORDER_OPENED: (state, action) => ({
    ...state,
    ordersOpened: action.payload,
  }),
  REMOVE_ORDER_BY_ID: (state, action) => {
    const updatedOrdersPending = state.ordersPending.filter(
      (i) => i.orderId !== action.payload
    );
    const updatedOrdersOpened = state.ordersOpened.filter(
      (i) => i.orderId !== action.payload
    );

    const orderToRemove = state.ordersPending
      .concat(state.ordersOpened)
      .find((i) => i.orderId === action.payload);

    if (orderToRemove) {
      try {
        orderToRemove?.orderTradingView?.remove();
      } catch (error) {
        console.error("Erro ao remover ordem:", error);
      }
    }
    return {
      ...state,
      ordersPending: updatedOrdersPending,
      ordersOpened: updatedOrdersOpened,
    };
  },
  REMOVE_ORDER_OPENED_BY_ID: (state, action) => {
    const { ordersOpened } = state;

    const updatedOrdersOpened = ordersOpened.filter(
      (i) => i.orderId !== action.payload
    );

    const orderToRemove = ordersOpened.find(
      (i) => i.orderId === action.payload
    );

    if (orderToRemove) {
      try {
        orderToRemove.orderTradingView.remove();
      } catch (error) {}
    }

    return {
      ...state,
      ordersOpened: updatedOrdersOpened,
    };
  },
  REMOVE_ORDER_PENDING_BY_ID: (state, action) => {
    const { ordersPending } = state;

    const updatedOrdersPending = ordersPending.filter(
      (i) => i.orderId !== action.payload
    );

    const orderToRemove = ordersPending.find(
      (i) => i.orderId === action.payload
    );

    if (orderToRemove) {
      try {
        window.tvWidget
          .activeChart()
          .removeEntity(orderToRemove.orderTradingView._line._id);
      } catch (error) {}
    }

    return {
      ...state,
      ordersPending: updatedOrdersPending,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const TradingViewContext = createContext<TradingViewContextValue>({
  ...initialState,
  setWidget: () => {},
  addChartOrders: () => null,
  setChartOrders: () => null,
  removeChartOrders: () => null,
  winLoseChartOrder: () => null,
  removeOrdersByTimeframe: () => null,
  setCandleTime: () => null,
  addEventPending: () => null,
  removeEventPending: () => null,
  updateSymbol: () => null,
  closeAllOrders: () => null,
  removeOrderById: () => null,
  removeOrdersBySymbol: () => null,
  removeOrderOpenedById: () => null,
  removeOrderPendingById: () => null,
});

const orderLocks = new Set<string>(); // Armazenará as ordens atualmente sendo processadas

export const TradingViewProvider: FC<TradingViewProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [defaultSymbol, setDefaultSymbol] = useLocalStorage(
    "defaultSymbol",
    "IDXUSDT"
  );
  const [defaultCandleTimeFrame, setDefaultCandleTimeFrame] = useLocalStorage(
    "defaultCandleTimeFrame",
    "M1"
  );

  useEffect(() => {
    const symbol = defaultSymbol || "IDXUSDT";
    updateSymbol(symbol);

    // Chamar a função para atualizar o currentChartAnalysis
    updateCurrentChartAnalysis(defaultSymbol);
  }, [defaultSymbol]);

  const setWidget = (tvWidget: IChartingLibraryWidget) => {
    dispatch({ type: "SET_TV_WIDGET", payload: tvWidget });
  };

  const setChartOrders = (chartOrders: ChartOrders[]) => {
    dispatch({ type: "SET_CHART_ORDERS", payload: chartOrders });
  };

  const addEventPending = (event: any) => {
    dispatch({ type: "SET_EVENTS_PENDING", payload: event });
  };

  const removeEventPending = () => {
    dispatch({ type: "REMOVE_EVENTS_PENDING", payload: null });
  };

  const setCandleTime = (timestamp: number) => {
    dispatch({ type: "SET_CANDLE_TIME", payload: timestamp });
  };

  // Função para garantir que a ordem não está bloqueada
  const processOrderSafely = (orderId: string, action: () => void) => {
    if (!orderLocks.has(orderId)) {
      orderLocks.add(orderId); // Bloquear a ordem
      action(); // Executar ação (criação/remoção)
      orderLocks.delete(orderId); // Desbloquear após a ação
    }
  };

  const removeOrdersByTimeframe = async (currentTimeframe: string) => {
    const promisesOpened = state.ordersOpened
      .filter((o) => o.timeframe !== currentTimeframe)
      .map((order) => {
        removeOrderOpenedById(order.orderId);
      });

    const promisesPending = state.ordersPending
      .filter((o) => o.timeframe !== currentTimeframe)
      .map((order) => {
        removeOrderPendingById(order.orderId);
      });

    await Promise.all([...promisesOpened, ...promisesPending]);
  };

  const removeAllOrdersByTimeframe = async () => {
    const promisesOpened = state.ordersOpened.map((order) => {
      removeOrderOpenedById(order.orderId);
    });

    const promisesPending = state.ordersPending.map((order) => {
      removeOrderPendingById(order.orderId);
    });

    await Promise.all([...promisesOpened, ...promisesPending]);
  };

  const addChartOrders = async (orders: Order[], currentTimeframe: string) => {
    try {
      await delay(window.tvWidget ? 0 : 5000);

      const cleanOrders = orders.length === 0;

      if (cleanOrders) {
        removeAllOrdersByTimeframe();
        return;
      }

      await removeOrdersByTimeframe(currentTimeframe);
      // Continua com a lógica para agrupar e adicionar ordens conforme a necessidade...

      const groupedOrders = (direction: string) =>
        orders
          .filter((order) => order.direction.toUpperCase() === direction)
          .reduce((result, order) => {
            const identifier = `${order.candleStartTime}-${direction}`;
            if (!result[identifier]) result[identifier] = [];
            result[identifier].push(order);
            return result;
          }, {});

      const groupedOrdersBull = groupedOrders("BULL");
      const groupedOrdersBear = groupedOrders("BEAR");
      const bands = calculateBollingerBands();

      console.log({
        groupedOrdersBull,
        groupedOrdersBear,
        bands,
      });

      processPendingOrders(groupedOrdersBull, "BULL");
      processPendingOrders(groupedOrdersBear, "BEAR");

      processOpenOrders(groupedOrdersBull, "BULL");
      processOpenOrders(groupedOrdersBear, "BEAR");
    } catch (error) {
      console.log("addChartOrders error: ", error);
    }
  };

  const processOpenOrders = (groupedOrders: any, direction: any) => {
    Object.entries(groupedOrders).forEach(
      ([identifiers, orders]: [string, Order[]]) => {
        if (orders[0].status.toUpperCase() !== "OPEN") return;
        if (orders[0].direction.toUpperCase() !== direction) return;

        const [candleStartTime] = identifiers.split("-") as [string, any];
        const baseUniqueId = `order-${direction}-${candleStartTime}`;
        const isBull = direction === "BULL";
        const color = isBull ? "#08C58A" : "#FF025C";
        const orderIndex = orders.length - 1;
        const price = orders[orderIndex].cop || orders[orderIndex].price;

        orders.forEach((order) => {
          const orderPendingId = `${baseUniqueId}-PENDING`;
          state.ordersPending
            .filter((i) => i.orderId.includes(orderPendingId))
            .forEach((orderToRemove) =>
              removeOrderPendingById(orderToRemove.orderId)
            );
        });

        const totalOrderValue = orders.reduce(
          (acc, order) => acc + (order.accept ? order.accept : order.invest),
          0
        );

        const existOrder = state.ordersOpened.find(
          (i) => i.orderId === `${baseUniqueId}-OPEN`
        );

        if (existOrder) {
          updateOrderOpened({
            ...existOrder,
            totalOrderValue,
          });
          return;
        }

        const createOrder = () => {
          try {
            const calloutPoint = calculateCalloutPoint(
              orders[0].candleTimeFrame,
              parseInt(candleStartTime),
              price,
              isBull,
              false
            );
            const orderShape = createCalloutOrder({
              label: `$ ${totalOrderValue}`,
              color,
              calloutPoint,
            });

            console.warn("NEW ORDER", {
              orderId: `${baseUniqueId}-OPEN`,
              order: orders[0],
              orderTradingView: orderShape,
              direction,
              timeframe: orders[0].candleTimeFrame,
              candleStartTime: orders[0].candleStartTime,
              totalOrderValue,
              symbol: orders[0].symbol,
              status: "OPEN",
            });

            addOrderOpened({
              orderId: `${baseUniqueId}-OPEN`,
              order: orders[0],
              orderTradingView: orderShape,
              direction,
              timeframe: orders[0].candleTimeFrame,
              candleStartTime: orders[0].candleStartTime,
              totalOrderValue,
              symbol: orders[0].symbol,
              status: "OPEN",
            });
          } catch (error) {
            setTimeout(() => checkIfLastBarVisible(0), 500);
          }
        };

        const checkIfLastBarVisible = (attempts = 0) => {
          const maxAttempts = 10;
          const mainSeries = window.tvWidget.activeChart().getSeries();
          const isVisible = mainSeries && mainSeries.isVisible();

          if (isVisible) {
            createOrder();
          } else if (attempts < maxAttempts) {
            setTimeout(() => checkIfLastBarVisible(attempts + 1), 500); // Verifica a cada 500ms
          } else {
            console.error(
              "Não foi possível criar a ordem após várias tentativas."
            );
          }
        };

        checkIfLastBarVisible();
      }
    );
  };

  const processPendingOrders = (groupedOrders: any, direction: any) => {
    Object.entries(groupedOrders).forEach(
      ([identifiers, orders]: [string, Order[]]) => {
        if (orders[0].status.toUpperCase() !== "PENDING") return;
        if (orders[0].direction.toUpperCase() !== direction) return;

        const symbol = orders[0].symbol;
        const [candleStartTime] = identifiers.split("-") as [string, any];
        const baseUniqueId = `order-${direction}-${candleStartTime}-PENDING`;
        const color = "#D89611";

        orders.forEach((order) => {
          const orderId = `${baseUniqueId}-${order.id}`;
          processOrderSafely(orderId, () => {
            const existOrder = state.ordersPending.find(
              (i) => i.orderId === `${baseUniqueId}-${order.id}`
            );

            if (existOrder) {
              const currentTime = Date.now();
              const orderTime = new Date(order.candleStartTime).getTime();
              const timeFrameInMs = {
                M1: 1 * 60 * 1000,
                M5: 5 * 60 * 1000,
                M15: 15 * 60 * 1000,
              }[order.candleTimeFrame];

              const invalidOpenOrder = currentTime - orderTime >= timeFrameInMs;

              if (invalidOpenOrder) {
                removeOrderPendingById(existOrder.orderId);
              }
              return;
            }

            const price = order.cop || order.price;
            const label = formatCurrency(order.invest);
            const calloutPoint = calculateCalloutPoint(
              order.candleTimeFrame,
              parseInt(candleStartTime),
              price,
              direction === "BULL",
              true
            );

            const createOrder = () => {
              try {
                const orderShape = createCalloutOrder({
                  label,
                  color,
                  calloutPoint,
                });

                addOrderPending({
                  orderId: `${baseUniqueId}-${order.id}`,
                  order,
                  orderTradingView: orderShape,
                  direction,
                  timeframe: order.candleTimeFrame,
                  candleStartTime: order.candleStartTime,
                  totalOrderValue: order.accept ? order.accept : order.invest,
                  symbol,
                  status: "PENDING",
                });
              } catch (error) {
                setTimeout(checkIfLastBarVisible, 500);
              }
            };

            const checkIfLastBarVisible = (attempts = 0) => {
              const maxAttempts = 10;
              const mainSeries = window.tvWidget.activeChart().getSeries();
              const isVisible = mainSeries && mainSeries.isVisible();

              if (isVisible) {
                createOrder();
              } else if (attempts < maxAttempts) {
                setTimeout(() => checkIfLastBarVisible(attempts + 1), 500);
              } else {
                console.error(
                  "Não foi possível criar a ordem após várias tentativas."
                );
              }
            };

            checkIfLastBarVisible();
          });
        });
      }
    );
  };

  const removeOrdersBySymbol = (symbol: string) => {
    state.ordersPending
      .concat(state.ordersOpened)
      .filter((o) => o.symbol === symbol)
      .forEach((order) => {
        try {
          removeOrderById(order.orderId);
        } catch (error) {
          console.log("removeOrdersBySymbol error => ", error);
        }
      });
  };

  const createCalloutOrder = ({
    color,
    label,
    calloutPoint,
  }: {
    color: string;
    label: string;
    calloutPoint: {
      startTime: number;
      endTime: number;
      priceStart: number;
      priceEnd: number;
    };
  }) => {
    const order = window.tvWidget
      .activeChart()
      .createOrderLine()
      .setText(label)
      .setLineLength(6)
      .setLineStyle(3)
      .setQuantity("00:00")
      .setEditable(true)
      .setLineColor(color)
      .setQuantityBorderColor(color)
      .setQuantityBackgroundColor(color)
      .setBodyBorderColor(color)
      .setBodyTextColor(color)
      .setBodyBackgroundColor("rgb(20, 20, 24)")
      .setPrice(calloutPoint.priceStart);

    return order;
  };

  const updateSymbol = (symbol: string) => {
    setDefaultSymbol(correctSymbol(symbol));
    dispatch({ type: "SET_SELECTED_SYMBOL", payload: correctSymbol(symbol) });
  };

  const addOrderPending = (order: OrderItem) => {
    dispatch({ type: "ADD_ORDER_PENDING", payload: order });
  };

  const addOrderOpened = (order: OrderItem) => {
    dispatch({ type: "ADD_ORDER_OPENED", payload: order });
  };

  const updateOrderOpened = (order: OrderItem) => {
    const { ordersOpened } = state;
    const updatedOrdersOpened = ordersOpened.map((o) =>
      o.orderId === order.orderId ? order : o
    );

    dispatch({ type: "UPDATE_ORDER_OPENED", payload: updatedOrdersOpened });
  };

  const removeOrderPendingById = (orderId: string) => {
    dispatch({ type: "REMOVE_ORDER_PENDING_BY_ID", payload: orderId });
  };

  const removeOrderOpenedById = (orderId: string) => {
    dispatch({ type: "REMOVE_ORDER_OPENED_BY_ID", payload: orderId });
  };

  const removeOrderById = (orderId: string) => {
    dispatch({ type: "REMOVE_ORDER_BY_ID", payload: orderId });
  };

  const closeAllOrders = () => {
    dispatch({ type: "SET_CHART_ORDERS", payload: [] });
  };

  const winLoseChartOrder = async (order: WinLoseOrderType, status: string) => {
    const winStatus = status === OrderStatusEnum.WIN;
    const backgroundColor = winStatus ? "#08C58A" : "#FF025C";
    const value = formatCurrency(order.value);
    const formattedValue = winStatus ? `+U${value}` : `-U${value}`;
    const text = `Resultado \n${formattedValue} `;

    if (order.symbol !== defaultSymbol) return;

    // Obter o valor de fechamento do candle atual
    const lastDailyBar = getLastBar(defaultSymbol, "winLoseChartOrder");

    if (!lastDailyBar) {
      console.error("Não foi possível obter o valor de fechamento do candle.");
      return;
    }

    const closePrice = lastDailyBar.close;
    const closeTime = lastDailyBar.time;

    const activeChart = window.tvWidget.activeChart();
    const calloutId = activeChart.createMultipointShape(
      [
        { time: closeTime, price: closePrice },
        { time: closeTime, price: closePrice },
      ],
      {
        shape: "callout",
        text,
        lock: true,
        disableSelection: true,
        disableUndo: true,
        disableSave: true,
        overrides: {
          bold: true,
          fontsize: window.innerWidth <= 768 ? 12 : 14,
          textAlign: "center",
          backgroundColor,
          borderColor: backgroundColor,
          drawBorder: true,
          linewidth: 1,
          transparency: 0,
        },
        zOrder: "top",
      }
    );

    window.requestAnimationFrame(() => console.log("Gráfico atualizado!"));

    setTimeout(() => {
      activeChart.removeEntity(calloutId);
    }, 3500);
  };

  const removeChartOrders = (groupedOrders: GroupedOrders[]) => {
    const { chartOrders } = state;

    try {
      if (groupedOrders.length > 0) {
        let _chartOrders = chartOrders;
        for (const orderToRemove of groupedOrders) {
          _chartOrders = _chartOrders.filter((o) => o.id !== orderToRemove.id);

          const chartOrder = chartOrders.find(
            (chartOrder) => chartOrder.id === orderToRemove.id
          );

          if (chartOrder) {
            window.tvWidget.activeChart().removeEntity(chartOrder.entities[0]);
          }
        }

        setChartOrders(_chartOrders);
      }
    } catch (error) {
      console.log("removeChartOrders error => ", error);
    }
  };

  const calculateNewValue = (
    value: number,
    bull: boolean,
    bb?: BollingerBands,
    timeFrame?: string
  ) => {
    let newValue = value;
    let offset = 0;

    if (timeFrame && bb) {
      offset = bb.maxHeight;
    }
    newValue = bull ? newValue + offset : newValue - offset;

    return newValue;
  };

  const calculateBollingerBands = (): BollingerBands => {
    const period = 20;
    const factor = 2;

    const { _items } = window.tvWidget
      .activeChart()
      .getSeries() //@ts-ignore
      .data()
      .bars();

    const candles = _items;
    const prices = candles.slice(-period).map((candle) => candle.value[4]);
    const sma = prices.reduce((sum, price) => sum + price, 0) / period;
    const stdDev = Math.sqrt(
      prices.reduce((sum, price) => sum + Math.pow(price - sma, 2), 0) / period
    );
    const upperBand = sma + stdDev * factor;
    const lowerBand = sma - stdDev * factor;
    const maxHeight = upperBand - lowerBand;

    return { sma, upperBand, lowerBand, maxHeight };
  };

  const calculateCalloutPoint = (
    timeFrame: string,
    candleStartTime: number,
    price: number,
    isBull: boolean,
    pending: boolean,
    bb?: BollingerBands
  ) => {
    const priceStart = price;
    const priceEnd = calculateNewValue(priceStart, isBull, bb, timeFrame);

    const startTime = pending
      ? convertTimeToCandlePoint(candleStartTime, "subMinutes", 1)
      : candleStartTime;

    let minutes = 0;
    switch (timeFrame) {
      case "M1":
        minutes = pending ? 6 : 5;
        break;
      case "M5":
        minutes = pending ? 30 : 25;
        break;
      case "M15":
        minutes = pending ? 90 : 75;
        break;
      default:
        break;
    }
    const endTime = convertTimeToCandlePoint(
      candleStartTime,
      pending ? "subMinutes" : "addMinutes",
      minutes
    );

    return { startTime, endTime, priceStart, priceEnd };
  };

  return (
    <TradingViewContext.Provider
      value={{
        ...state,
        setWidget,
        addChartOrders,
        setChartOrders,
        removeChartOrders,
        winLoseChartOrder,
        removeOrdersByTimeframe,
        setCandleTime,
        addEventPending,
        removeEventPending,
        updateSymbol,
        closeAllOrders,
        removeOrderById,
        removeOrdersBySymbol,
        removeOrderOpenedById,
        removeOrderPendingById,
      }}
    >
      {children}
    </TradingViewContext.Provider>
  );
};

TradingViewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TradingViewConsumer = TradingViewContext.Consumer;

export default TradingViewContext;
