import { useContext } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import LayoutContext from "src/contexts/LayoutContext";

const EyeShowSensitiveInfo = ({ size }: { size: number }) => {
  const { layout, setShowSensiveInfo } = useContext(LayoutContext);

  return (
    <span style={{ cursor: "pointer" }}>
      {layout.showSensiveInfo ? (
        <IoEyeOff
          className="eyeIcon"
          size={size}
          onClick={setShowSensiveInfo}
        />
      ) : (
        <IoEye className="eyeIcon" size={size} onClick={setShowSensiveInfo} />
      )}
    </span>
  );
};

export default EyeShowSensitiveInfo;
