import Dexie, { Table } from "dexie";
import { OrderBookItem } from "../types/order";
// import {VolumeBook} from "../types/candle";

export class ExchangeDB extends Dexie {
  book!: Table<OrderBookItem, number>;
  // volume!: Table<VolumeBook, number>;

  constructor() {
    super("ExchangeDB");
    this.version(1.21).stores({
      book: "&id, candleNumber, userId, direction, [direction+candleNumber]",
      volume: "++id, &candleNumber",
    });
  }

  cleanCandle(candleNumber: number) {
    return this.transaction("rw", this.book, () => {
      this.book.where({ candleNumber }).delete();
      // this.volume.where({candleNumber}).delete();
    });
  }
}

export const db = new ExchangeDB();

export function resetDatabase() {
  return db.transaction("rw", [db.book], async () => {
    await Promise.all(db.tables.map((table) => table.clear()));
  });
}

export function saveAccountId(
  accountId: string,
  message?: string,
  reloadPage?: boolean
) {
  window.localStorage.setItem("accountId", accountId);
  if (reloadPage) {
    window.location.reload();
  }
}
