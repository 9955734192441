import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";

const ForexWarning: FC = () => {
  const { t } = useTranslation("forexWarning");
  const [forexCurrencyWarning, setForexCurrencyWarning] = useLocalStorage(
    "forexCurrencyWarning",
    false
  );

  if (forexCurrencyWarning) {
    return null;
  }

  return (
    <Container
      sx={{
        position: "fixed",
        bottom: 16,
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 99,
      }}
    >
      <Paper elevation={2} sx={{ padding: 4 }}>
        <Stack
          direction="row"
          spacing={4}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box>
            <Typography variant="h6">{t("title")}</Typography>
            <Typography>{t("description")}</Typography>
          </Box>
          <Button
            variant="contained"
            color="info"
            size="large"
            sx={{ whiteSpace: "nowrap", paddingX: 8 }}
            onClick={() => setForexCurrencyWarning(true)}
          >
            {t("button")}
          </Button>
        </Stack>
      </Paper>
    </Container>
  );
};

export default ForexWarning;
