import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";

const CookiesWarning: FC = () => {
  const { t } = useTranslation("cookies");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [cookiesAgree, setCookiesAgree] = useLocalStorage(
    "cookiesAgree",
    false
  );

  if (cookiesAgree) {
    return null;
  }

  return (
    <Container
      sx={{
        position: "fixed",
        bottom: 16,
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 99,
      }}
    >
      <Paper elevation={2} sx={{ padding: 2 }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            <Typography variant="h6">{t("title")}</Typography>
          </Box>
          <Button
            variant="contained"
            size={"medium"}
            sx={{ whiteSpace: "nowrap", paddingX: 8 }}
            onClick={() => setCookiesAgree(true)}
          >
            {t("button")}
          </Button>
        </Stack>
      </Paper>
    </Container>
  );
};

export default CookiesWarning;
