import { Grid } from "@mui/material";
import type { FC } from "react";
import { useEffect } from "react";
import { CountdonwProvider } from "src/contexts/CountdownContext";
import useApiData from "../../hooks/useApiData";
import PlaceOrderForm from "./forms/PlaceOrderForm";

const PlaceOrder: FC = () => {
  const { candles, selectedCandle } = useApiData();

  useEffect(() => {
    if (!candles) {
      return;
    }

    const current = candles.filter((item) => item.candle === selectedCandle);
    if (current[0]) {
    }
  }, [candles, selectedCandle]);

  return (
    <Grid item>
      <CountdonwProvider>
        <PlaceOrderForm />
      </CountdonwProvider>
    </Grid>
  );
};

export default PlaceOrder;
