import {
  Backdrop,
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { format, subDays } from "date-fns";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Logo from "src/components/Logo";
import useQuery from "src/hooks/useQuery";
import useDeposits from "src/swr/use-deposits";
import labelsColors from "src/theme/labelsColors";
import { clearDepositIntervalId } from "src/utils/interval";
import translate from "src/utils/translatorUtil";
import Refresh from "../../../icons/Refresh";
import type { Deposit } from "../../../types/deposit";

const columns = ["ID", "date", "value", "status", "category"];

const DEPOSIT_STATUS_LIST = ["all_statuses", "CONFIRMED", "PENDING", "EXPIRED"];

const INIT_ACTIVE_DEPOSIT_FILTERS_DATA = {
  depositStatus: DEPOSIT_STATUS_LIST[0],
  dateFrom: subDays(new Date(), 30),
  dateTo: new Date(),
};

const DepositListTable: FC = () => {
  const { t } = useTranslation("dashboard");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const query = useQuery();

  const depositId = query.get("depositId");
  const status = query.get("status");

  const [activeDepositFiltersData, setActiveDepositFiltersData] = useState(
    INIT_ACTIVE_DEPOSIT_FILTERS_DATA
  );
  const [dateFrom, setDateFrom] = useState(
    INIT_ACTIVE_DEPOSIT_FILTERS_DATA.dateFrom
  );
  const [dateTo, setDateTo] = useState(INIT_ACTIVE_DEPOSIT_FILTERS_DATA.dateTo);
  const [page, setPage] = useState(0);
  const [queryFormat, setQueryFormat] = useState("");

  const fetchDeposits = async () => {
    const queryFormat = formatQueryParams();
    setQueryFormat(queryFormat);
  };

  const {
    data: deposits,
    totalElements,
    numberOfElements,
    loading: loadingDeposits,
  } = useDeposits({
    query: queryFormat,
    page,
  });

  useEffect(() => {
    fetchDeposits();
  }, [activeDepositFiltersData, depositId, status]);

  const formatQueryParams = () => {
    let query = "";

    const { depositStatus, dateFrom, dateTo } = activeDepositFiltersData;

    query =
      query +
      `&depositStatuses=${
        depositStatus === "all_statuses"
          ? "CONFIRMED,PENDING,EXPIRED"
          : depositStatus
      }`;

    if (dateFrom) {
      query = query + `&from=${format(dateFrom, "yyyy-MM-dd")}`;
    }

    if (dateTo) {
      query = query + `&to=${format(dateTo, "yyyy-MM-dd")}`;
    }

    return query;
  };

  const changeSelect = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setActiveDepositFiltersData({
      ...activeDepositFiltersData,
      [name]: value,
    });
  };

  const handleChangeDate = (date: Date, name: string) => {
    setActiveDepositFiltersData({
      ...activeDepositFiltersData,
      [name]: date,
    });
  };

  const handleChangePage = (page: number) => {
    setPage(page);
    fetchDeposits();
  };

  const handleClickeDeposit = (item: Deposit) => {
    clearDepositIntervalId();
    const gatewayTransactionType = item?.gatewayTransactionType ?? "crypto";
    navigate(
      `/dashboard/profile?tab=deposit&depositId=${item.id}&status=${item.status}&gatewayTransactionType=${gatewayTransactionType}`
    );
  };

  return (
    <>
      <Card
        sx={{
          background: "#070f14",
          padding: isMobile ? "1.5rem" : "3rem",
          borderRadius: "8px",
          fieldset: { border: "none" },
        }}
      >
        <CardHeader
          title={t("deposit_history")}
          sx={{ fontSize: 14 }}
          action={
            loadingDeposits ? (
              <CircularProgress size={25} sx={{ mt: 0.5, mr: 1 }} />
            ) : (
              <IconButton aria-label="refresh" onClick={() => fetchDeposits()}>
                <Refresh />
              </IconButton>
            )
          }
        />
        <Divider />
        <Stack
          direction={isMobile ? "column" : "row"}
          sx={{ mt: 3, mr: 1, ml: 1, display: "flex" }}
          spacing={2}
        >
          <FormControl fullWidth sx={{ minWidth: 120 }}>
            <Select
              labelId="select-coin"
              id="select-statuse"
              name="depositStatus"
              value={activeDepositFiltersData.depositStatus}
              onChange={changeSelect}
            >
              {DEPOSIT_STATUS_LIST.map((status) => (
                <MenuItem key={status} value={status}>
                  {t(status.toLowerCase())}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ display: "flex", gap: "0.5rem" }}>
            <FormControl fullWidth sx={{ minWidth: 120 }}>
              <MobileDatePicker
                label={t("from")}
                inputFormat="dd/MM/yyyy"
                value={dateFrom}
                onChange={(e) => setDateFrom(e)}
                onAccept={(value) => handleChangeDate(value, "dateFrom")}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            <FormControl fullWidth sx={{ minWidth: 120 }}>
              <MobileDatePicker
                label={t("until")}
                inputFormat="dd/MM/yyyy"
                value={dateTo}
                onChange={(e) => setDateTo(e)}
                onAccept={(value) => handleChangeDate(value, "dateTo")}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Box>
        </Stack>

        <Box
          sx={{
            backgroundImage: "background.primary",
            mt: 3,
            mr: 1,
            ml: 1,
            fontSize: "80%",
            "& .MuiTableHead-root": {
              backgroundColor: "#10191e !important",
            },
          }}
        >
          <Backdrop
            sx={{
              color: "#fff",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loadingDeposits}
          >
            <Logo />
            <CircularProgress color="inherit" sx={{ mt: 4 }} />
          </Backdrop>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "auto",
            }}
          >
            <Table
              padding={isMobile ? "checkbox" : "normal"}
              size={`${isMobile ? "small" : null}`}
            >
              <TableHead sx={{ backgroundColor: "#f4f4f414" }}>
                <TableRow>
                  {columns.map((item) => (
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: isMobile ? 8 : "0.71875rem",
                        width: `${100 / 6}%`,
                      }}
                      key={item}
                    >
                      {t(item)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  backgroundColor: "#070f14",
                }}
              >
                {deposits.length > 0 ? (
                  deposits.map((item, index) => (
                    <TableRow
                      hover
                      key={index}
                      sx={{
                        cursor: "pointer",
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                        ".css-8boiwb-MuiTableCell-root.MuiTableCell-paddingCheckbox":
                          {
                            padding: isMobile && "0 0 0 10px !important",
                          },
                      }}
                      onClick={() => handleClickeDeposit(item)}
                    >
                      <TableCell align="center" size="small">
                        <Tooltip title={item.id}>
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            sx={{
                              fontSize: isMobile ? 7 : 14,
                            }}
                          >
                            {`${item.id?.slice(0, 10)}...`}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center" size="small">
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                          sx={{
                            fontSize: isMobile ? 7 : 14,
                          }}
                        >
                          {format(
                            new Date(item.createdAt),
                            "dd/MM/yyyy, HH:mm:ss"
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" size="small">
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                          sx={{
                            fontSize: isMobile ? 7 : 14,
                          }}
                        >
                          {item.amount}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" size="small">
                        <Chip
                          label={t(translate(item.status))}
                          sx={{
                            ...labelsColors[item.status],
                            fontWeight: 400,
                            fontSize: isMobile ? 7 : 14,
                          }}
                        />
                      </TableCell>
                      <TableCell align="center" size="small">
                        {item.gatewayTransactionType ?? ""}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell rowSpan={1} colSpan={6} sx={{ color: "red" }}>
                      <Box sx={{ my: 1 }}>
                        {t("not_operations_with_filters")}
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {deposits.length !== 0 && (
            <TablePagination
              rowsPerPageOptions={[numberOfElements]}
              component="div"
              count={totalElements}
              rowsPerPage={numberOfElements}
              page={page}
              onPageChange={(e, page) => handleChangePage(page)}
              sx={{ color: "#00A667" }}
            />
          )}
        </Box>
      </Card>
    </>
  );
};

export default DepositListTable;
