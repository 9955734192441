import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import PlaceOrder from "./PlaceOrder";

export default function TradeOrder() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <PlaceOrder />
    </Box>
  );
}
