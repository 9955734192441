import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  Accordion,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/styles";
import { format } from "date-fns";
import { orderBy } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCopy } from "react-icons/bs";
import { symbolsInfo } from "src/constants";
import useApiData from "src/hooks/useApiData";
import { apiGet } from "src/services/apiService";
import useAvailableSymbols from "src/swr/use-available-symbols";
import useParameters from "src/swr/use-parameters";
import labelsColors from "src/theme/labelsColors";
import { Order } from "src/types/order";
import { currencyFormat } from "src/utils/currencyFormat";
import { useLocalStorage } from "usehooks-ts";
import { scrollStyle, winLostIconStyle } from ".";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#00000090" : "#00000090",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start !important",
  paddingLeft: 0,
  "& .info": {
    display: "flex",
    alignItems: "center",
  },
  "& .profit": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "0.25rem",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.mode === "dark" ? "#00000080" : "#00000080",
}));

const HistoryOrders = () => {
  const { t } = useTranslation("dashboard");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { userOrders, updateUserOrders } = useApiData();
  const [copiedLink, setCopiedLink] = useState<string | null>(null);
  const [currentTab, setCurrentTab] = useLocalStorage<string>(
    "currentTab",
    "order_book"
  );
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    "panel1"
  );

  const { parameters, loading } = useParameters();

  const { symbols } = useAvailableSymbols();

  const fetchOrders = async () => {
    if (!parameters) {
      return;
    }
    try {
      const candleTimeFrames = JSON.parse(parameters.CANDLE_TIME_FRAMES.value);
      const timeFrames = candleTimeFrames.map((item) => item.value).join(",");
      const symbolsList = symbols.map((item) => item.symbol).join(",");

      const response = await apiGet<Order[]>(
        `/orders?candleTimeFrames=${timeFrames}&symbols=${symbolsList}&statuses=CANCELED,REFUNDED,WIN,LOSE&page=0&size=10`
      );

      const combinedData = response.map((apiItem) => {
        const matchingSymbol = symbolsInfo.find(
          (symbolInfo) => symbolInfo.name === apiItem.symbol
        );

        return {
          ...apiItem,
          symbol: matchingSymbol?.symbol,
          image: matchingSymbol?.image,
        };
      });

      await updateUserOrders(
        orderBy(combinedData, [(item) => new Date(item.createdAt)], ["desc"])
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getOrders = useCallback(async () => {
    await fetchOrders();
  }, [fetchOrders]);

  useEffect(() => {
    getOrders();
  }, []);

  const handleChangeAccordion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccordion(newExpanded ? panel : false);
    };

  const handleCopy = (text: string) => {
    if (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopiedLink(text);
          setTimeout(() => {
            setCopiedLink(null);
          }, 1500);
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  console.log("Arquivo HistoryOrders");

  return (
    <Box sx={scrollStyle}>
      {userOrders.map((item) => (
        <Accordion
          key={item.id}
          expanded={expandedAccordion === item.id}
          onChange={handleChangeAccordion(item.id)}
          elevation={0}
          sx={{
            "& .MuiAccordionSummary-content": {
              display: "flex",
              justifyContent: "space-between",
            },
            "& .MuiAccordionSummary-root": {
              bgcolor: "transparent",
              background: "transparent",
              height: "64px",
              border: `0`,
              borderBottom: `1px solid #0d1b24`,
              "&:last-of-type": {
                border: 0,
              },
            },
            background: "transparent",
            "&::before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            sx={{
              "& .MuiAccordionSummary-content": {
                justifyContent: "space-around",
              },
            }}
          >
            <div className="info">
              <img width={40} src={item.image} />
              <div>
                <Typography color={"#EEE"}>{item.symbol}</Typography>
                <Typography variant="body1" color={"#797979"}>
                  {format(new Date(item.candleStartTime), "HH:mm")} /{" "}
                  {item.candleTimeFrame}
                </Typography>
              </div>
            </div>
            <div className="profit" style={{ width: isMobile && "150px" }}>
              {item.direction.toUpperCase() === "BULL" ? (
                <Box sx={winLostIconStyle} ml={1} color={"#1bba87"}>
                  <TrendingUpIcon fontSize={isMobile ? "large" : "small"} />
                </Box>
              ) : (
                <Box sx={winLostIconStyle} ml={1} color={"#FF025C"}>
                  <TrendingDownIcon fontSize={isMobile ? "large" : "small"} />
                </Box>
              )}
              <Typography
                color={labelsColors[item.status].color}
                variant="subtitle1"
              >
                {item.status.toUpperCase() === "WIN"
                  ? `+ ${currencyFormat(item.profit)}`
                  : item.status.toUpperCase() === "LOSE"
                  ? `- ${currencyFormat(item.accept)}`
                  : currencyFormat(item.accept)}
              </Typography>
            </div>
          </AccordionSummary>
          {currentTab === "historic" && (
            <AccordionDetails>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => handleCopy(item.id)}
              >
                <Typography color="#606f79" variant="subtitle1">
                  ID:{" "}
                  <Typography
                    color={copiedLink !== null ? "#1bba87" : "#EEE"}
                    display="inline-block"
                    variant="body1"
                    mr={1}
                  >
                    {copiedLink !== null ? `${t("copied")}!` : item.id}
                  </Typography>
                  <BsCopy size={14} color="#606f79" />
                </Typography>
              </Box>
              <Box>
                <Typography color="#606f79" variant="subtitle1">
                  {t("par_comercial")}:{" "}
                  <Typography
                    color="#EEE"
                    display="inline-block"
                    variant="body1"
                  >
                    {item.symbol}
                  </Typography>
                </Typography>
              </Box>
              <Box>
                <Typography color="#606f79" variant="subtitle1">
                  {t("created_at")}:{" "}
                  <Typography
                    color="#EEE"
                    display="inline-block"
                    variant="body1"
                  >
                    {format(new Date(item.createdAt), "HH:mm")}
                  </Typography>
                </Typography>
              </Box>
              <Box>
                <Typography color="#606f79" variant="subtitle1">
                  {t("direction")}:{" "}
                  <Typography
                    color="#EEE"
                    display="inline-block"
                    variant="body1"
                  >
                    {item.direction.toUpperCase() === "BEAR"
                      ? t("bear")
                      : t("bull")}
                  </Typography>
                </Typography>
              </Box>
              <Box>
                <Typography color="#606f79" variant="subtitle1">
                  {t("open_price")}:{" "}
                  <Typography
                    color="#EEE"
                    display="inline-block"
                    variant="body1"
                  >
                    {currencyFormat(item.cop)}
                  </Typography>
                </Typography>
              </Box>
              <Box>
                <Typography color="#606f79" variant="subtitle1">
                  {t("close_price")}:{" "}
                  <Typography
                    color="#EEE"
                    display="inline-block"
                    variant="body1"
                  >
                    {currencyFormat(item.ccp)}
                  </Typography>
                </Typography>
              </Box>
            </AccordionDetails>
          )}
        </Accordion>
      ))}
    </Box>
  );
};

export default memo(HistoryOrders);
