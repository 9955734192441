import { Box, IconButton } from "@mui/material";
import { useContext } from "react";
import SettingsContext from "src/contexts/SettingsContext";
import { HiSpeakerWave, HiSpeakerXMark } from "react-icons/hi2";
import { BiSupport } from "react-icons/bi";

export default function SoundButton() {
  const { settings, saveSettings } = useContext(SettingsContext);

  const handleToggleSound = (e: any): void => {
    e.stopPropagation();
    saveSettings({
      ...settings,
      isSoundOn: !settings.isSoundOn,
    });
  };

  return (
    <Box sx={{ alignSelf: "center" }}>
      <IconButton
        sx={{
          padding: 0,
          "&:hover": { background: "transparent" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {settings.isSoundOn ? (
            <HiSpeakerWave
              color="#1bba87"
              size={24}
              onClick={e => handleToggleSound(e)}
              title="Turn off result sound"
            />
          ) : (
            <HiSpeakerXMark
              color="#475760"
              size={24}
              onClick={e => handleToggleSound(e)}
              title="Turn on result sound"
            />
          )}
          {/* <BiSupport
            color="#475760"
            size={24}
            title="Support"
            onClick={() => alert("support")}
          /> */}
        </Box>
      </IconButton>
    </Box>
  );
}
