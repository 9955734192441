export function getLastBar(symbol, method) {
  const subscriptionItem = channelToSubscription.get(symbol);

  // console.log("getLastBar", {
  //   symbol,
  //   method,
  //   subscriptionItem,
  // });
  if (subscriptionItem === undefined) {
    return {
      time: 0,
      open: 0,
      high: 0,
      low: 0,
      close: 0,
    };
  }

  if (!subscriptionItem.lastDailyBar) {
    return {
      time: 0,
      open: 0,
      high: 0,
      low: 0,
      close: 0,
    };
  }
  return subscriptionItem.lastDailyBar;
}

function getMinutes(timestamp) {
  return new Date(timestamp).getMinutes();
}

const channelToSubscription = new Map();

export function candleUpgradeOnStream(data, symbol) {
  const { t, p } = data;

  const tradePrice = parseFloat(p);
  const tradeTime = parseInt(t);

  if (isNaN(tradePrice) || isNaN(tradeTime)) {
    return;
  }

  const subscriptionItem = channelToSubscription.get(symbol);

  // console.log("subscriptionItem", subscriptionItem);
  if (subscriptionItem === undefined) {
    return;
  }

  const lastDailyBar = subscriptionItem.lastDailyBar;
  const tradeTimeMinutes = getMinutes(tradeTime);

  let bar;
  if (
    lastDailyBar.open === 0 ||
    tradeTimeMinutes !== getMinutes(lastDailyBar.time)
  ) {
    bar = {
      time: tradeTime,
      open: tradePrice,
      high: tradePrice,
      low: tradePrice,
      close: tradePrice,
    };
  } else {
    bar = {
      ...lastDailyBar,
      high: Math.max(lastDailyBar.high, tradePrice),
      low: Math.min(lastDailyBar.low, tradePrice),
      close: tradePrice,
    };
  }
  subscriptionItem.lastDailyBar = bar;

  subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
}
export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback,
  lastDailyBar
) {
  console.log("subscribeOnStream", {
    symbolInfo,
    resolution,
    subscriberUID,
  });
  const channelString = symbolInfo.full_name;
  const handler = {
    id: subscriberUID,
    callback: onRealtimeCallback,
  };
  let subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    subscriptionItem.handlers.push(handler);
    return;
  }
  subscriptionItem = {
    subscriberUID,
    resolution,
    lastDailyBar,
    handlers: [handler],
  };
  channelToSubscription.set(channelString, subscriptionItem);
}

export function unsubscribeOnStream(subscriberUID) {
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );

    if (handlerIndex !== -1) {
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
}
