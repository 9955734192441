import axios from "axios";
import { getHours } from "date-fns";
import PropTypes from "prop-types";
import { FC, ReactNode, useEffect, useState } from "react";
import MaintenanceScreen from "./MaintenanceScreen";

interface MaintenanceGuardProps {
  children?: ReactNode;
}

const MaintenanceGuard: FC<MaintenanceGuardProps> = ({ children }) => {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const checkMaintenanceSchedule = async () => {
    const res = await axios.get(
      "http://worldtimeapi.org/api/timezone/America/Sao_Paulo"
    );

    const data = res.data;

    const hours = getHours(new Date(data.datetime));

    if (hours >= 0 && hours <= 19) {
      setIsMaintenance(true);
    }
  };

  useEffect(() => {
    // checkMaintenanceSchedule();
  }, []);

  if (isMaintenance) {
    return <MaintenanceScreen />;
  }

  return <>{children}</>;
};

MaintenanceGuard.propTypes = {
  children: PropTypes.node,
};

export default MaintenanceGuard;
