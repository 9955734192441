import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { FaCircleMinus, FaCirclePlus } from "react-icons/fa6";
import CountdownContext from "src/contexts/CountdownContext";
import TradingViewContext from "src/contexts/TradingViewContext";
import useParameters from "src/swr/use-parameters";
import formatCurrency from "src/utils/formatCurrency";
import { NumericOnly } from "src/utils/numericOnly";
import { notifyError, notifySuccess } from "src/utils/toast";
import useSound from "use-sound";
import useApiData from "../../../hooks/useApiData";
import TimeframeSelect from "../utils/TimeframeSelect";

import { useStompClient } from "react-stomp-hooks";
import orderClick from "src/assets/audio/notifications/mixkit-cool-interface-click-tone-2568.wav";
import { getLastBar } from "src/components/TVChartContainer/streaming";
import AccountContext from "src/contexts/AccountContext";
import SettingsContext from "src/contexts/SettingsContext";
import useOrderBook from "src/hooks/useOrderBook";
import useAvailableSymbols from "src/swr/use-available-symbols";
import { OrderBookItem } from "src/types/order";
import { getResulteOnLive } from "src/utils/getResultOnLine";
import { isValidOrder } from "src/utils/isValidOrder";
import { useLocalStorage } from "usehooks-ts";

const useStyles = {
  "& .MuiFormLabel-root": {
    textAlign: "center",
    color: "#919eab !important",
    fontSize: "1rem",
  },
  "& .MuiInputBase-input": {
    textAlign: "center",
    color: "#CCC",
  },
  "& .MuiInputBase-root": {
    fontSize: "1rem",
    background: "transparent",
  },
  svg: {
    color: "#475760",
    cursor: "pointer",
    "&:hover": {
      color: "#01DB97",
    },
  },
};

const PlaceOrderForm: FC = () => {
  const { t } = useTranslation("dashboard");
  const stompClient = useStompClient();

  const { parameters } = useParameters();
  let localStorageAmoun = localStorage.getItem("localStorageAmount");
  const [amount, setAmount] = useState<string>(
    localStorageAmoun ? localStorageAmoun : "0"
  );
  const [balance, setBalance] = useState(0);
  const [fee, setFee] = useState(10);
  const [playOrderClick] = useSound(orderClick);
  const { bookRed, bookGreen } = useOrderBook();
  const [purchase, setPurchase] = useState(0);
  const [sale, setSale] = useState(0);

  const {
    selectedCandle,
    userBalance,
    orderValue,
    setOrderValue,
    handleSingleUserOrder,
    handleRemoveLiveOperationsOrder,
    userLiveOperations,
  } = useApiData();
  const { activeAccount } = useContext(AccountContext);

  const {
    tvWidget,
    selectedSymbol,
    ordersOpened,
    ordersPending,
    removeOrderOpenedById,
  } = useContext(TradingViewContext);
  const { count, timeFormat } = useContext(CountdownContext);
  const { settings } = useContext(SettingsContext);
  const [, setCurrentTab] = useLocalStorage<string>("currentTab", "order_book");
  const lastBar = getLastBar(selectedSymbol, "PlaceOrderForm");
  const { selectedSymbolData } = useAvailableSymbols();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile2 = useMediaQuery(theme.breakpoints.down("md"));

  // useEffect(() => {
  //   // Temporário: usar o valor do parâmetro FEE_RATE para calcular a taxa de serviço depois
  //   if (parameters) {
  //     setFee(parameters?.FEE_RATE?.value);
  //   }
  // }, [parameters]);

  useEffect(() => {
    if (localStorageAmoun) {
      increaseAmount(Number(localStorageAmoun));
    } else {
      increaseAmount(orderValue);
    }
  }, []);

  useEffect(() => {
    setFee(100 - selectedSymbolData.payout);
  }, [selectedSymbolData]);

  useEffect(() => {
    if (ordersOpened.length > 0 || ordersPending.length > 0) {
      // Atualizar apenas as ordens abertas
      ordersOpened.forEach((order) => {
        const invalidOpenOrder = !isValidOrder(order.order);

        if (invalidOpenOrder) {
          if (order.orderTradingView) {
            removeTradingviewOrder(order.orderId, order.orderTradingView);
          }
        } else {
          updateOrderResultOpened({
            order: order.order,
            orderTradingView: order.orderTradingView,
            totalOrderValue: order.totalOrderValue,
          });
        }
      });

      // Atualizar apenas as ordens pendentes
      ordersPending.forEach((order) => {
        const invalidPendingOrder = !isValidOrder(order.order);

        if (invalidPendingOrder) {
          if (order.orderTradingView) {
            removeTradingviewOrder(order.orderId, order.orderTradingView);
          }
        } else {
          updateOrderResultOpened({
            order: order.order,
            orderTradingView: order.orderTradingView,
            totalOrderValue: order.order.accept,
            isPending: true,
          });
        }
      });
    }
  }, [timeFormat, ordersOpened, ordersPending, parameters]);

  useEffect(() => {
    Object.keys(userLiveOperations).forEach((key) => {
      const order = userLiveOperations[key];
      const invalidPendingOrder = !isValidOrder(order);
      if (invalidPendingOrder) {
        handleRemoveLiveOperationsOrder(key);
      }
    });
  }, [timeFormat, userLiveOperations]);

  // Função de atualização otimizada
  const updateOrderResultOpened = useCallback(
    ({
      order,
      orderTradingView,
      totalOrderValue = 0,
      isPending = false,
    }: {
      order: any;
      orderTradingView: any;
      totalOrderValue: number;
      isPending?: boolean;
    }) => {
      // Cálculo centralizado de estimateIncome
      const amount = totalOrderValue || 0;
      let estimateIncome = parseFloat(
        (amount * 2 - amount * (fee / 100)).toFixed(3)
      );

      // Obter as cores e o símbolo baseado no resultado da função getResulteOnLive
      const { color, simbol, status } = getResulteOnLive({
        order,
        fee,
        lastDailyBar: lastBar,
      });

      // Formatação do resultado
      const numericResult = parseFloat((estimateIncome - amount).toFixed(2));
      const formattedResult = formatCurrency(
        simbol === "+" ? numericResult : amount
      );
      const label = `${simbol}${formattedResult}`;

      // Adicionar try-catch para proteger contra tentativas de acessar um orderTradingView removido
      try {
        // Verificar se `orderTradingView` ainda existe antes de tentar acessá-lo
        if (
          orderTradingView &&
          typeof orderTradingView.setText === "function"
        ) {
          if (orderTradingView.getText() !== label && !isPending) {
            orderTradingView.setText(label);
          }

          if (orderTradingView.getQuantity() !== timeFormat) {
            orderTradingView.setQuantity(timeFormat);
          }

          if (orderTradingView.getLineColor() !== color) {
            orderTradingView.setBodyTextColor(color);
          }
        }
      } catch (error) {
        console.error("Erro ao atualizar a ordem:", error);
      }
    },
    [timeFormat, selectedSymbolData]
  );

  const removeTradingviewOrder = (orderId: string, orderTradingView: any) => {
    try {
      orderTradingView?.remove();
      removeOrderOpenedById(orderId);
    } catch (error) {
      console.error("Erro ao remover a ordem:");
    }
  };

  const increaseAmount = (orderValue?: number) => {
    if (orderValue) {
      setAmount(orderValue.toString());
      localStorage.setItem("localStorageAmount", orderValue.toString());
      return;
    }

    const newAmount = parseFloat(amount) + 1;
    setOrderValue(newAmount);
    setAmount(newAmount.toString());
    localStorage.setItem("localStorageAmount", newAmount.toString());
  };

  const decreaseAmount = () => {
    if (parseFloat(amount) < 2) return;
    const newAmount = parseFloat(amount) - 1;
    setOrderValue(newAmount);
    setAmount(newAmount.toString());
    localStorage.setItem("localStorageAmount", newAmount.toString());
  };

  const calculatePercentageIncome = () => {
    const numericAmount = parseFloat(amount);
    if (numericAmount === 0 || userBalance === 0) return 0;
    const percentageIncome = ((income - numericAmount) * 100) / numericAmount;
    if (Number.isNaN(percentageIncome)) {
      return 0;
    }
    return percentageIncome.toFixed(0);
  };

  const placeOrder = async (type: string): Promise<void> => {
    if (parseFloat(amount) > balance) {
      await notifyError("Quantidade não disponível em carteira.");
      return;
    }

    try {
      const direction = type.toUpperCase() as any;
      const invest = parseFloat(amount);

      //@ts-ignore
      const { _items, _end } = tvWidget.activeChart().getSeries().data().bars();
      const candle = _items[_end - 1].value;

      stompClient.publish({
        destination: "/topic/execute",
        body: JSON.stringify({
          accountId: activeAccount.id,
          candleTimeFrame: selectedCandle,
          symbol: selectedSymbol,
          direction,
          invest,
          asset: parameters?.DEFAULT_COIN?.value.toUpperCase(),
          price: candle[4],
        }),
      });

      setCurrentTab("operations");
      if (!isMobile) {
        notifySuccess("Ordem criada com sucesso!");
      }

      if (settings.isSoundOn) {
        playOrderClick();
      }
    } catch (err) {
      if (err === "Locked window") {
        notifyError("Aguarde alguns segundos para fazer o envio da ordem");
        return;
      } else if (err === "SimultaneousRequestsException") {
        notifyError(
          "Várias ordens detectadas em sequência. Aguarde e tente novamente."
        );
        return;
      }
      notifyError("Oops! Não foi possível enviar a ordem.");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (NumericOnly(e)) {
      setAmount(e.target.value === "" ? "" : e.target.value);
      setOrderValue(parseFloat(e.target.value));
      localStorage.setItem("localStorageAmount", e.target.value);
    }
  };

  const handleBlur = () => {
    if (amount === "") {
      setAmount("1");
      setOrderValue(1);
    }
  };

  useEffect(() => {
    setBalance(userBalance);
  }, [setBalance, setAmount, userBalance]);

  const income = useMemo(() => {
    const numericAmount = parseFloat(amount);
    const estimateIncome = numericAmount * 2 - numericAmount * (fee / 100);
    return parseFloat(estimateIncome.toFixed(3));
  }, [amount, fee]);

  const temp1: OrderBookItem[] =
    bookGreen && bookGreen.length > 0
      ? bookGreen.filter((it) => it.candleTimeFrame === selectedCandle)[0]
          ?.orderBook || []
      : [];
  const temp2: OrderBookItem[] =
    bookRed && bookRed.length > 0
      ? bookRed.filter((it) => it.candleTimeFrame === selectedCandle)[0]
          ?.orderBook || []
      : [];

  useEffect(() => {
    const totalPurchase = temp1.reduce((acc, curr) => acc + curr.invest, 0);
    const totalSale = temp2.reduce((acc, curr) => acc + curr.invest, 0);

    if (totalPurchase || totalSale) {
      const totalInvest = totalPurchase + totalSale;

      const purchasePercent = (totalPurchase / totalInvest) * 100;
      const salePercent = (totalSale / totalInvest) * 100;

      setPurchase(purchasePercent);
      setSale(salePercent);
    } else {
      setPurchase(50);
      setSale(50);
    }
  }, [temp1, temp2]);

  return (
    <Box
      sx={{
        outline: "1px solid #0b1721",
        bgcolor: "#040c11",
        borderRadius: 1,
        px: isMobile ? 1 : 2,
        pt: isMobile && 2,
        pb: isMobile && 1,
        mt: !isMobile && 2,
      }}
    >
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 12, sm: 8, md: 12 }}
        sx={useStyles}
      >
        <Grid
          item
          xs={isMobile ? 6 : 12}
          sx={{
            my: 0,
            flexDirection: isMobile ? "column-reverse" : "column",
            display: "flex",
          }}
        >
          <TimeframeSelect />
        </Grid>
        <Grid item xs={isMobile ? 6 : 12} sx={{ mb: isMobile ? 0.5 : 0 }}>
          <TextField
            size={isMobile ? "small" : "medium"}
            sx={{
              "& .MuiInputBase-root input": {
                fontSize: isMobile ? "1rem" : "1.25rem",
                padding: isMobile ? "8.5px 0" : "0.850rem 0",
              },
            }}
            value={amount}
            label={`${t("value")} (U$)`}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaCircleMinus size={24} onClick={() => decreaseAmount()} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    backgroundColor: "#15181A",
                  }}
                >
                  <FaCirclePlus size={24} onClick={() => increaseAmount()} />
                </InputAdornment>
              ),
            }}
          />
          {isMobile && (
            <Stack
              direction="column"
              justifyContent="center"
              spacing={2}
              marginTop={0.7}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "top",
                    gap: "2px",
                  }}
                >
                  <Typography
                    color="#606f79"
                    fontWeight={400}
                    variant="caption"
                    fontSize={amount.length > 4 ? "10px" : "12px"}
                  >
                    {t("revenue")}
                  </Typography>
                </Box>
                <Typography
                  color="#01DB97"
                  fontWeight={600}
                  variant="caption"
                  fontSize={amount.length > 4 ? "10px" : "14px"}
                  sx={{ lineHeight: 1 }}
                >
                  {`+${formatCurrency(income - parseInt(amount))}`}
                </Typography>
              </Box>
            </Stack>
          )}
        </Grid>
      </Grid>

      <Grid item sx={{ my: isMobile ? 0 : 2, pb: isMobile ? 0 : "16px" }}>
        {!isMobile && (
          <Grid item xs={isMobile ? 12 : 6}>
            <Stack direction="column" justifyContent="center" spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "row" : "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1px",
                  paddingBottom: 2,
                }}
              >
                <Box sx={{ display: "flex", gap: "0.5rem" }}>
                  <Typography
                    color="#606f79"
                    fontWeight={400}
                    variant="caption"
                    fontSize={".85rem"}
                  >
                    {t("revenue")}
                  </Typography>
                  {!isMobile && (
                    <Typography
                      color="#01DB97"
                      fontSize="1rem"
                      fontWeight={600}
                    >
                      +{`${calculatePercentageIncome()}%`}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ position: "relative" }}>
                  <Typography
                    color="#01DB97"
                    variant="caption"
                    fontSize={isMobile2 ? "1rem" : "2.2rem"}
                    sx={{
                      lineHeight: 1,
                      fontWeight: 500,
                      fontSize: amount.length >= 6 ? "2rem" : "2.2rem",
                    }}
                  >
                    {`+${formatCurrency(income - parseInt(amount))}`}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Grid>
        )}

        {isMobile && (
          <Box
            className="box_container"
            style={{
              display: "flex",
              color: "#CCC",
              alignItems: "center",
              gap: "0.5rem",
              fontSize: "14px",
              paddingBottom: "0.5rem",
            }}
          >
            <span className="compra" style={{ display: "inline-block" }}>
              {purchase === 0 ? 0 : purchase.toFixed(0)}%
            </span>
            <span
              className="ativo_linha"
              style={{
                display: "flex",
                width: "100%",
                height: "4px",
                position: "relative",
                gap: "4px",
              }}
            >
              <span
                className="compra_bar"
                style={{
                  display: "inline-block",
                  height: "100%",
                  width: `${purchase}%`,
                  backgroundColor: "rgb(27, 186, 135)",
                  transition: "width 1s ease",
                  borderRadius: "16px",
                }}
              ></span>
              <span
                className="venda_bar"
                style={{
                  display: "inline-block",
                  height: "100%",
                  width: `${100 - purchase}%`,
                  backgroundColor: "rgb(255, 2, 92)",
                  transition: "width 1s ease",
                  borderRadius: "16px",
                }}
              ></span>
            </span>
            <span className="venda" style={{ display: "inline-block" }}>
              {sale === 0 ? 0 : sale.toFixed(0)}%
            </span>
          </Box>
        )}

        <Stack
          direction={isMobile ? "row" : "column"}
          spacing={isMobile ? 2 : 1}
          justifyContent="space-between"
          alignContent={"end"}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              minHeight: isMobile ? 48 : 70,
              borderRadius: "8px",
              display: "flex",
              gap: "0.5rem",
              padding: isMobile ? "0" : null,
              "&:hover": {
                backgroundColor: "#08865a",
              },
            }}
            onClick={() => placeOrder("bull")}
            disabled={parseInt(amount) === 0 || count < 6}
          >
            <Typography
              fontSize="0.875rem"
              fontWeight="600"
              textTransform="uppercase"
            >
              {t("bull")}
            </Typography>
            <TrendingUpIcon fontSize={isMobile ? "large" : "medium"} />
          </Button>

          {!isMobile && (
            <Box
              className="box_container"
              style={{
                display: "flex",
                color: "#CCC",
                alignItems: "center",
                gap: "0.5rem",
                fontSize: "14px",
              }}
            >
              <span className="compra" style={{ display: "inline-block" }}>
                {purchase === 0 ? 0 : purchase.toFixed(0)}%
              </span>
              <span
                className="ativo_linha"
                style={{
                  display: "flex",
                  width: "100%",
                  height: "4px",
                  position: "relative",
                  gap: "4px",
                }}
              >
                <span
                  className="compra_bar"
                  style={{
                    display: "inline-block",
                    height: "100%",
                    width: `${purchase}%`,
                    backgroundColor: "rgb(27, 186, 135)",
                    transition: "width 1s ease",
                    borderRadius: "16px",
                  }}
                ></span>
                <span
                  className="venda_bar"
                  style={{
                    display: "inline-block",
                    height: "100%",
                    width: `${100 - purchase}%`,
                    backgroundColor: "rgb(255, 2, 92)",
                    transition: "width 1s ease",
                    borderRadius: "16px",
                  }}
                ></span>
              </span>
              <span className="venda" style={{ display: "inline-block" }}>
                {sale === 0 ? 0 : sale.toFixed(0)}%
              </span>
            </Box>
          )}

          <Button
            sx={{
              width: "100%",
              minHeight: isMobile ? 40 : 70,
              borderRadius: "8px",
              backgroundColor: "#FF025C",
              gap: "0.5rem",
              padding: isMobile ? "0" : null,
              "&:hover": {
                backgroundColor: "#CC024D",
              },
            }}
            variant="contained"
            onClick={() => placeOrder("bear")}
            disabled={parseInt(amount) === 0 || count < 6}
          >
            <Typography
              fontSize="0.875rem"
              fontWeight="600"
              textTransform="uppercase"
            >
              {t("bear")}
            </Typography>
            <TrendingDownIcon fontSize={isMobile ? "large" : "medium"} />
          </Button>
        </Stack>
      </Grid>
    </Box>
  );
};

export default PlaceOrderForm;
